import React from "react";
import { Route } from "react-router-dom";

import OldLayout from "~componentes/app/OldLayout";
import { AsyncCotizadorSeguros } from "~componentes/app/LazyComponents";

const SegurosRoutes = () => [
  <Route key="seguros-1" path="/seguros">
    <OldLayout>
      <AsyncCotizadorSeguros />
    </OldLayout>
  </Route>
];

export default SegurosRoutes;
