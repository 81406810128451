import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/styles";

import { clearTieneGNC, setTieneGNC } from "../actions";

import multimarcasIcon from "~assets/icons/ic_multimarcas.svg";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import { CheckboxButton } from "~components/styled/checkboxButton/CheckboxButton";
import Typography from "@material-ui/core/Typography";

const VehiculoTieneGNCStep = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(clearTieneGNC());
  }, [dispatch]);

  const handleClick = value => {
    dispatch(setTieneGNC({ value, selected: true }));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle label={"¿El vehículo tiene GNC?"} marginBottom={60} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: 20
        }}
      >
        <div style={{ marginRight: 5, width: "100%" }}>
          <CheckboxButton onClick={() => handleClick(true)}>
            <div className={classes.container}>
              <img
                src={multimarcasIcon}
                style={{ height: 63, marginBottom: 17 }}
              />
              <Typography className={classes.btnLabel}>
                Sí, tiene GNC
              </Typography>
            </div>
          </CheckboxButton>
        </div>
        <div style={{ marginLeft: 5, width: "100%" }}>
          <CheckboxButton onClick={() => handleClick(false)}>
            <div className={classes.container}>
              <img
                src={multimarcasIcon}
                style={{ height: 58, marginBottom: 17 }}
              />
              <Typography className={classes.btnLabel}>
                No, no tiene GNC
              </Typography>
            </div>
          </CheckboxButton>
        </div>
      </div>
    </ContentSectionWithActions>
  );
};

export default VehiculoTieneGNCStep;

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 145,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  btnLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    lineHeight: 1.38,
    letterSpacing: 0.48,
    textAlign: "center",
    color: theme.palette.primary.main
  }
}));
