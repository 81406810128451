import { useEffect, useState } from "react";
import { initialize as ldClientInitialize } from "ldclient-js";
import environment from "~src/environment";
import { changeFlag } from "./js/actions";
import { useDispatch, useSelector } from "react-redux";
import localRepository from "~src/localRepository";

const featureFlagsDefaults = localRepository.featureFlags.get();

const useFeatureFlags = user => {
  const [ldUser, setLdUser] = useState({});

  const [client, setClient] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const newUser = user
      ? {
          key: user.email,
          name: user.preferred_username,
          email: user.email
        }
      : {};

    if (newUser.key !== ldUser.key) setLdUser(newUser);
  }, [ldUser, user]);

  useEffect(() => {
    if (ldUser.key && !client) {
      const clientInit = ldClientInitialize(
        environment.launchDarkly.sdkKey,
        ldUser
      );
      setClient(clientInit);
    } else if (ldUser.key && client) {
      client.identify(ldUser).then(newFlags => {
        dispatch(changeFlag(newFlags));
      });
    } else {
      setClient(null);
    }
  }, [ldUser, client]);

  useEffect(() => {
    const getFlags = async () => {
      await client.waitForInitialization();

      const flagsKeys = Object.keys(client.allFlags());

      const initialFlags = flagsKeys.reduce((result, current) => {
        const value = client.variation(current, featureFlagsDefaults[current]);
        return { ...result, [current]: value };
      }, {});

      dispatch(changeFlag(initialFlags));

      client.on(`change`, flagsChangedEvent => {
        const flagsChanged = Object.keys(flagsChangedEvent).reduce(
          (result, current) => {
            return {
              ...result,
              [current]: flagsChangedEvent[current].current
            };
          },
          {}
        );

        dispatch(changeFlag(flagsChanged));
      });
    };

    if (client) {
      console.log("getFlags");

      getFlags();
    }
  }, [client]);
};

export default useFeatureFlags;
