import React from "react";
import muiMGTheme from "~css/muiMGTheme";
import ContentLoader from "react-content-loader";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  smallFont: {
    fontSize: "normal"
  },
  normalFont: {
    fontSize: "1.2rem"
  },
  disabled: {},
  button: {
    "&$disabled": {
      background: muiMGTheme.palette.white,
      color: muiMGTheme.palette.grey5,
      lineHeight: "16px",
      fontWeight: "bold",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      padding: 0,
      width: "100%",
      height: "100%"
    },
    justifyContent: "center",
    display: "flex",
    alignItems: "baseline",
    padding: 0,
    width: "100%",
    background: muiMGTheme.palette.white,
    color: muiMGTheme.palette.grey5,
    fontWeight: "bold",
    lineHeight: "16px",
    height: "100%",
    borderRadius: 0
  },
  innerTriangle: {
    borderRight: "15px solid #d5d5d5",
    borderBottom: "15px solid transparent",
    position: "absolute"
  },
  innerTriangleSelected: {
    borderRight: "15px solid #44459C",
    borderBottom: "15px solid transparent",
    position: "absolute"
  },

  buttonContainer: {
    display: "flex",
    background: muiMGTheme.palette.white,
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: 167,
    height: 53,
    borderRight: "1px solid #d5d5d5",
    borderTop: "1px solid #d5d5d5"
  },
  buttonContainerSelected: {
    display: "flex",
    background: muiMGTheme.palette.white,
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: 167,
    height: 53,
    border: "2px solid #44459C"
  },
  loading: { width: "100%", marginTop: "1%" },
  buttonContent: {
    display: "flex",
    flexDirection: "column",
    textDecoration: "lowercase",
    justifyContent: "space-around",
    paddingTop: "4px"
  },
  detailsText: {
    fontSize: "12px",
    color: muiMGTheme.palette.verdeMgBroker,
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "normal",
    textDecoration: "underline",
    textTransform: "lowercase"
  }
}));

const PolizaButton = ({ onClick, poliza, selected, isLoading }) => {
  const classes = useStyles();

  let font = "normalFont";
  if (poliza && poliza.importe >= 10000) font = "smallFont";

  if (isLoading) return <LoadingButton />;
  return (
    <div
      className={
        !selected ? classes.buttonContainer : classes.buttonContainerSelected
      }
    >
      <div className={classes.button}>
        <Button
          classes={{
            root: classes.button,
            disabled: classes.disabled
          }}
          onClick={onClick(poliza)}
          disabled={!poliza}
        >
          <>
            {poliza ? (
              <div className={classes.buttonContent}>
                <NumberFormat
                  className={classes[font]}
                  value={poliza.importe}
                  displayType={"text"}
                  decimalScale={0}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$ "}
                  suffix={" "}
                />
                <Typography className={classes.detailsText} variant="subtitle2">
                  ver detalle
                </Typography>
              </div>
            ) : (
              <>&mdash;</>
            )}
          </>
        </Button>
      </div>
      <div
        className={
          !selected ? classes.innerTriangle : classes.innerTriangleSelected
        }
      />
    </div>
  );
};

export default PolizaButton;

const useStylesLoading = makeStyles(theme => ({
  root: {
    display: "flex",
    background: muiMGTheme.palette.white,
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: 167,
    height: 53,
    borderRight: "1px solid #d5d5d5",
    borderTop: "1px solid #d5d5d5"
  },
  buttonLoading: {
    display: "flex",
    background: muiMGTheme.palette.white,
    alignItems: "baseline",
    justifyContent: "center",
    width: "100%",
    paddingRight: 0,
    paddingLeft: 0,
    height: "100%",
    borderRadius: 0
  },
  buttonPrefix: {
    color: muiMGTheme.palette.verdeMgBroker,
    fontSize: "1.2rem",
    padding: "10px 0px 0px 10px",
    fontWeight: "bold"
  },
  innerTriangle: {
    borderRight: "15px solid #d5d5d5",
    borderBottom: "15px solid transparent",
    position: "absolute"
  },

  loading: { width: 60, height: 40 }
}));

const LoadingButton = () => {
  const classes = useStylesLoading();

  return (
    <div className={classes.root}>
      <div className={classes.buttonLoading}>
        <Typography className={classes.buttonPrefix}>{"$"}</Typography>
        <div className={classes.loading}>
          <ContentLoader
            height={40}
            width={60}
            speed={1}
            primaryColor="#f7f8ff"
            secondaryColor="#d9e8df"
            //backgroundColor="#f7f8ff"
            //foregroundColor="#d9e8df"
          >
            <rect x="4" y="26" rx="4" ry="4" width="49" height="16" />
          </ContentLoader>
        </div>
      </div>
      <div className={classes.innerTriangle} />
    </div>
  );
};
