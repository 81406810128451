import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "~componentes/Auth0/useAuth";
import Modal from "./Modal";
import terminosyCondicionesPath from "~components/app/terminosYCondiciones/terminosYCondiciones.md";
import ReactMarkdown from "react-markdown";
import environment from "../../../../../environment";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

import { checkTerminosYCondiciones, aceptarTerminos } from "./actions";

const useReduxSelector = () =>
  useSelector(state => ({
    mostrarTerminos:
      state.sharedReducers.terminosYCondicionesReducer.mostrarTerminos
  }));

const appData = environment.terminosYCondiciones;

const TerminosYCondiciones = () => {
  const dispatch = useDispatch();
  const { authState } = useAuth0();
  const history = useHistory();
  const { mostrarTerminos } = useReduxSelector();
  const [textoTerminos, setTextoTerminos] = useState("");
  const [opened, setOpened] = useState(mostrarTerminos);
  useEffect(() => {
    dispatch(checkTerminosYCondiciones(authState.user));
  }, []);

  useEffect(() => {
    setOpened(mostrarTerminos);
  }, [setOpened, mostrarTerminos]);

  useEffect(() => {
    const func = async () => {
      const response = await fetch(terminosyCondicionesPath);
      const text = await response.text();
      setTextoTerminos(text);
    };
    func();
  }, []);

  const handleAceptar = () => {
    dispatch(
      aceptarTerminos(
        authState.user.euroUserId,
        authState.user.preferred_username
      )
    );
  };
  const handleRechazar = () => {
    history.push("/signout");
  };

  return (
    <>
      <Modal
        opened={opened}
        title={`TÉRMINOS Y CONDICIONES`}
        dialogActions={
          <>
            <CallToActionButton
              variant="outlined"
              label={"Rechazar"}
              onClick={handleRechazar}
            />
            <CallToActionButton label={"Aceptar"} onClick={handleAceptar} />
          </>
        }
      >
        <ReactMarkdown
          source={
            "#### V" +
            appData.version +
            " - Vigencia: " +
            appData.fecha +
            "  \n\n" +
            textoTerminos
          }
        />
      </Modal>
    </>
  );
};
export default TerminosYCondiciones;
