import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/styles";

import { setTipoAgencia, clearTipoAgencia } from "../actions";
import concesionarioIcon from "~assets/icons/ic_concesionario.svg";
import multimarcasIcon from "~assets/icons/ic_multimarcas.svg";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import { CheckboxButton } from "~components/styled/checkboxButton/CheckboxButton";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Typography from "@material-ui/core/Typography";
import MarcaVehiculoSelector from "~components/bussines/marcaVehiculoSelector/MarcaVehiculoSelector";

const TIPO_AGENCIA_CONCESIONARIA = 1;
const TIPO_AGENCIA_MULTIMARCA = 2;

const TipoAgenciaStep = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [concesionariaChecked, setConcesionariaChecked] = useState(false);
  const [multimarcasChecked, setMultimarcasChecked] = useState(false);

  const handleConcesionariaChecked = () => {
    setConcesionariaChecked(value => !value);
    setMultimarcasChecked(false);
  };
  const handleMultimarcaChecked = () => {
    setMultimarcasChecked(value => !value);
    setConcesionariaChecked(false);
  };

  const handleMarcaSelection = event => {
    dispatch(
      setTipoAgencia({
        tipo: TIPO_AGENCIA_CONCESIONARIA,
        marca: event.target.value.value
      })
    );
  };

  const handleContinue = () => {
    dispatch(setTipoAgencia({ tipo: TIPO_AGENCIA_MULTIMARCA, marca: null }));
  };

  useEffect(() => {
    dispatch(clearTipoAgencia());
  }, [dispatch]);

  return (
    <ContentSectionWithActions
      actions={
        <>
          {multimarcasChecked && (
            <ContentSection>
              <CallToActionButton
                disabled={!multimarcasChecked}
                label={"Continuar"}
                onClick={handleContinue}
              />
            </ContentSection>
          )}
        </>
      }
    >
      <PrimaryTitle label={"Elegí el tipo de agencia"} marginBottom={60} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: 20
        }}
      >
        <div style={{ marginRight: 5, width: "100%" }}>
          <CheckboxButton
            checked={concesionariaChecked}
            onClick={handleConcesionariaChecked}
          >
            <div className={classes.container}>
              <img
                src={concesionarioIcon}
                style={{ height: 63, marginBottom: 17 }}
              />
              <Typography className={classes.btnLabel}>
                Concesionaria
              </Typography>
            </div>
          </CheckboxButton>
        </div>
        <div style={{ marginLeft: 5, width: "100%" }}>
          <CheckboxButton
            checked={multimarcasChecked}
            onClick={handleMultimarcaChecked}
          >
            <div className={classes.container}>
              <img
                src={multimarcasIcon}
                style={{ height: 58, marginBottom: 17 }}
              />
              <Typography className={classes.btnLabel}>Multimarca</Typography>
            </div>
          </CheckboxButton>
        </div>
      </div>
      {concesionariaChecked && (
        <MarcaVehiculoSelector
          value={{ value: null, label: null }}
          onSelected={handleMarcaSelection}
        />
      )}
    </ContentSectionWithActions>
  );
};

export default TipoAgenciaStep;

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 145,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  btnLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    lineHeight: 1.38,
    letterSpacing: 0.48,
    textAlign: "center",
    color: theme.palette.primary.main
  }
}));
