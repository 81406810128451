import React from "react";
import muiMGTheme from "~css/muiMGTheme";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import NumberFormat from "react-number-format";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { tipoPoliza } from "~constants/tipoPoliza";
import logoSancor from "~assets/companiasDetalle/logo-sancor.svg";
import logoBerkley from "~assets/companiasDetalle/logo-berkley.svg";
import logoMercantilAndina from "~assets/companiasDetalle/logo-mercantilAndina.svg";
import logoRus from "~assets/companiasDetalle/logo-rus.svg";
import logoZurich from "~assets/companiasDetalle/logo-zurich.svg";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    height: "calc( 100vh - 120px )",
    margin: "4%",
  },
  link: {
    color: muiMGTheme.palette.brownGrey,
    fontWeigth: "bold",
    textTransform: "none",
    textDecoration: "none",
    fontSize: theme.typography.pxToRem(16),
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "40%",
    "@media (min-width:768px)": {
      height: "35%",
    },
  },
  compania: {
    textAlign: "center",
    fontWeight: 800,
    color: muiMGTheme.palette.violeta,
    paddingBottom: 16,
    display: "block",
    flex: 1,
    fontSize: "20px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.2",
    letterSpacing: "normal",
  },
  tipoPoliza: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
  },
  importeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
    paddingBottom: "30px",
  },
  importe: {
    textAlign: "center",
    fontWeight: 800,
    color: muiMGTheme.palette.violeta,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    alignItems: "center",
    bottom: "0",
    justifyContent: "center",
    height: 141,
    width: "100vw",
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), #f5f5fa 35%)`,
  },
  contratar: {
    display: "flex",
    height: "56px",
    width: 250,
    color: muiMGTheme.palette.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    borderRadius: 50,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  content: {
    paddingBottom: 60,
    overflow: "auto",
    height: "35%",
    "@media (min-width:768px)": {
      minWidth: "500px",
      height: "55%",
    },
  },
  sentence: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.33,
    textAlign: "stretch",
    color: muiMGTheme.palette.grey5,
  },
}));

const PolizaDetail = ({ poliza, onContratar }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const newLinesDeclared = poliza.descripcion.includes("\n");

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <img alt="" src={getLogo(poliza.companiaId)} />
        </div>
        <Typography variant={"subtitle1"} className={classes.tipoPoliza}>
          {tipoPoliza[poliza.tipoPlanComercial]}
        </Typography>

        <Typography variant={"subtitle1"} className={classes.compania}>
          {poliza.nombre}
        </Typography>

        <div className={classes.importeContainer}>
          <Typography variant={"h3"} className={classes.importe}>
            <NumberFormat
              value={poliza.importe}
              displayType={"text"}
              decimalScale={0}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
            />
          </Typography>
          <Typography variant={"subtitle1"} className={classes.tipoPoliza}>
            {"\xa0/mes"}
          </Typography>
        </div>
      </div>
      <div className={classes.content}>
        {newLinesDeclared &&
          convertStringIntoSentencesWithNewLines(poliza.descripcion).map(
            (sentence, index) => (
              <DialogContentText
                key={`poliza-item-sentence-${index}`}
                className={classes.sentence}
              >
                {sentence}
              </DialogContentText>
            )
          )}
        {!newLinesDeclared &&
          convertStringIntoSentencesWithOutNewLines(poliza.descripcion).map(
            (sentence, index) => (
              <div
                key={`poliza-item-sentence-${index}`}
                className={classes.sentence}
              >
                {sentence}
              </div>
            )
          )}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.contratar}
          onClick={onContratar}
          variant="contained"
          color="primary"
        >
          QUIERO ESTA PÓLIZA
        </Button>
        <div style={{ marginTop: 10 }}>
          <div onClick={() => history.goBack()} className={classes.link}>
            <Typography>Volver</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolizaDetail;

const getLogo = (companiaId) => {
  if (companiaId === "Sancor") return logoSancor;
  if (companiaId === "Zurich") return logoZurich;
  if (companiaId === "MercantilAndina") return logoMercantilAndina;
  if (companiaId === "RUS") return logoRus;
  if (companiaId === "Berkley") return logoBerkley;
};

const convertStringIntoSentencesWithNewLines = (description) => {
  return description.replace(/\n/g, "|").split("|");
};

const convertStringIntoSentencesWithOutNewLines = (description) => {
  return description
    .replace(/([.])\s*(?=[A-Z])/g, "$1|")
    .split("|")
    .map(convertFirstletterToUpperCase);
};

const convertFirstletterToUpperCase = (sentence) =>
  sentence && sentence.length > 2
    ? sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase()
    : sentence;
