import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const HOTJAR_CONFIG = [
  { url: "https://agencias.v3.mg-group.net.ar/", clientId: "1586788" },
  { url: "https://web.agencias.mg-group.net.ar/", clientId: "1642652" }
];

const useReduxSelector = () =>
  useSelector(state => ({
    isHotjarEnabled: state.featureFlagReducer.featureFlags["hotjar-mode"]
  }));

const useHotjar = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const { isHotjarEnabled } = useReduxSelector();

  useEffect(() => {
    if (isHotjarEnabled && !isLoaded) {
      const config = HOTJAR_CONFIG.find(x =>
        x.url.includes(window.location.origin)
      );

      if (config) hotjarLoader(config.clientId)();

      setIsLoaded(true);
    }
  }, [isLoaded, setIsLoaded, isHotjarEnabled]);
};

export default useHotjar;

const hotjarLoader = clientId => (
  h = window,
  o = document,
  t = "https://static.hotjar.com/c/hotjar-",
  j = ".js?sv=",
  a,
  r
) => {
  h.hj =
    h.hj ||
    function() {
      (h.hj.q = h.hj.q || []).push(arguments);
    };
  h._hjSettings = { hjid: clientId, hjsv: 6 };
  a = o.getElementsByTagName("head")[0];
  r = o.createElement("script");
  r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
};
