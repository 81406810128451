import React, { useMemo, memo } from "react";
import { Link } from "react-router-dom";
import { ListItem, List, makeSelectable } from "material-ui/List";
import Divider from "material-ui/Divider";
import { makeStyles, useTheme } from "../../libs/styles";

import { useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";

import newRequest from "material-ui/svg-icons/editor/attach-money";
import info from "material-ui/svg-icons/action/info";
import directions_car from "material-ui/svg-icons/maps/directions-car";
import seguros from "material-ui/svg-icons/hardware/security";
import inicioIcon from "material-ui/svg-icons/action/home";
import UserIcon from "material-ui/svg-icons/action/account-circle";
import {
  asegurarAutoButton,
  homeButton,
  contactarComercialButton
} from "../home/actions";

import { useAuth0 } from "../Auth0/useAuth";
import { nuevaCotizacionSeguros } from "../cotizadorSeguros/actions";

const useStyles = makeStyles(theme => ({
  item: {
    lineHeight: [["22px"], "!important"],
    "@media (min-width: 994px)": {
      fontSize: [[14], "!important"]
    }
  }
}));

const SelectableNavList = makeSelectable(List);

const MainMenu = memo(() => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authState } = useAuth0();

  const location = useLocation();
  const userName = authState.user
    ? authState.user.preferred_username.split(" ")[0]
    : "";
  const indexOffset = 1;
  const selectedIndex = useMemo(() => {
    const allPathnames = menuList.map(x => x.Link);
    return allPathnames.findIndex(
      x =>
        x === location.pathname ||
        (x.length > 1 && location.pathname.startsWith(x))
    );
  }, [location.pathname]);

  return (
    <SelectableNavList
      style={{ zIndex: 999 }}
      value={selectedIndex + indexOffset}
    >
      <ListItem
        primaryText={`Hola, ${userName}`}
        leftIcon={<UserIcon color={theme.palette.textColor} />}
        disabled={true}
        style={{ marginBottom: 8, fontWeight: 400 }}
      />
      <Divider />
      {menuList.map((item, index) => (
        <ListItem
          key={item.id}
          value={index + indexOffset}
          containerElement={
            <Link
              to={{ pathname: item.Link, state: { new: true } }}
              id={"menu_" + item.id}
            />
          }
          primaryText={item.Nombre}
          leftIcon={
            <item.Icon
              color={theme.palette.primary3Color}
              style={{ height: "50%" }}
            />
          }
          hoverColor={theme.palette.primary4Color}
          className={classes.item}
          onClick={
            item.action
              ? () => {
                  dispatch(item.action({ isSideBar: true }));
                }
              : null
          }
        />
      ))}

      {/* <Divider /> <ListItem
        id={"menu_salir"}
        key={"logout-999"}
        value={999}
        primaryText={lang.mainMenu.exitLabel}
        leftIcon={<ExitIcon color={theme.palette.primary3Color} />}
        containerElement={<Link to={"/signout"} />}
        className={classes.item}
      />
      <Divider />
      <ListItem
        id={"menu_appversion"}
        primaryText={localRepository.version.get()}
        leftIcon={<VersionIcon color={theme.palette.textColor} />}
        containerElement={isDevModeEnabled ? <Link to={"/devinfo"} /> : null}
        disabled={isDevModeEnabled ? false : true}
        className={classes.item}
      /> */}
    </SelectableNavList>
  );
});

export default MainMenu;

// private functions

const menuList = [
  {
    id: "0000",
    Nombre: "Inicio",
    Actividad: "001",
    Link: "/",
    Icon: inicioIcon,
    SubItems: [],
    action: homeButton
  },
  {
    id: "0001",
    Nombre: "Créditos",
    Actividad: "001",
    Link: "/creditos",
    Icon: newRequest,
    SubItems: [],
    action: null
  },
  {
    id: "0002",
    Nombre: "Seguros",
    Actividad: "001",
    Link: "/seguros",
    Icon: seguros,
    SubItems: [],
    action: asegurarAutoButton
  },
  {
    id: "0003",
    Nombre: "Autos",
    Actividad: "001",
    Link: "/autos",
    Icon: directions_car,
    SubItems: [],
    action: null
  },
  {
    id: "0004",
    Nombre: "Info",
    Actividad: "001",
    Link: "/info",
    Icon: info,
    SubItems: [],
    action: contactarComercialButton
  }
];
