import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import MessageViewer from "../notifications/MessageViewer";
import RedirectToHttps from "../shared/redirectToHttps";
import localRepository from "../../localRepository";

import NativeAppMessagingContainer from "../nativeAppMessaging";

import muiMGTheme from "~css/muiMGTheme";

import { makeStyles } from "~libs/styles";

// new material
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import mgTheme from "~assets/mgTheme";

import { useAuth0, useRefreshToken } from "../Auth0/useAuth";

import { logInUserAnalytics, logOutUserAnalytics } from "~libs/amplitudeHelper";
import LoadingView from "~componentes/auth/LoadingView";

import { AsyncRecoverPassword, AsyncTest } from "./LazyComponents";

import Signin from "~componentes/auth/Signin";
import { Callback } from "~componentes/Auth0/Callback";

import LoginFormContainer from "~componentes/user/LoginContainer";

import useHotjar from "~components/app/useHotjar";

import Routes from "~components/app/Routes";

import AuthenticationRoutes from "~components/app/authentication/Routes";

import { useFeatureFlags } from "../shared/featureFlags";

const PublicViews = () => {
  return (
    <>
      <Switch>
        <Route exact path="/signin" component={Signin} />
        <Route exact path="/callback" component={Callback} />
        <Route exact path="/loading" component={LoadingView} />
        <Route exact path="/login/old" component={LoginFormContainer} />
        <Route
          exact
          path="/login/password/:token"
          component={AsyncRecoverPassword}
        />
        <Route path="/test" component={AsyncTest} />
        <Redirect to="/signin" /> {/*fallback to login*/}
      </Switch>
    </>
  );
};

const ValidationViews = () => {
  return <AuthenticationRoutes />;
};

const AuthenticatedViews = () => {
  return <Routes />;
};

const useAppStyles = makeStyles(theme => ({
  nonScrollableBodyDefault: {
    backgroundColor: "#f9f7fc",
    position: "fixed",
    overflow: "hidden",
    width: "100%"
  }
}));

const App = ({ isOnNativeApp, nativeCredentials, featureFlagAutoprenda }) => {
  const classes = useAppStyles();

  useRefreshToken(isOnNativeApp, nativeCredentials);

  const { isAuthenticated, authState } = useAuth0();

  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  console.log("authState.user", authState.user);

  useEffect(() => {
    if (isLoggedIn && authState.user) {
      logInUserAnalytics(
        authState.user.email,
        authState.user.preferred_username,
        authState.user.sub,
        authState.user.euroUserId,
        localRepository.version.get()
      );
    } else {
      logOutUserAnalytics();
    }
  }, [authState.user, isLoggedIn]);

  useEffect(() => {
    document.body.className = classes.nonScrollableBodyDefault;
  }, [classes.nonScrollableBodyDefault]);

  useFeatureFlags(authState.user);

  useHotjar();

  const supportsHistory = "pushState" in window.history;

  return (
    <BrowserRouter keyLength={12} forceRefresh={!supportsHistory}>
      <Route
        render={({ location }) => {
          return (
            <>
              <NativeAppMessagingContainer />
              <CssBaseline />
              <ThemeProvider theme={mgTheme}>
                <MuiThemeProvider muiTheme={muiMGTheme}>
                  <RedirectToHttps>
                    <>
                      <MessageViewer />
                      {isAuthenticated &&
                        authState.user &&
                        authState.user.isAuthorized && (
                          <AuthenticatedViews
                            location={location}
                            isOnNativeApp={isOnNativeApp}
                            featureFlagAutoprenda={featureFlagAutoprenda}
                          />
                        )}
                      {isAuthenticated &&
                        !(authState.user && authState.user.isAuthorized) && (
                          <ValidationViews />
                        )}
                      {!isAuthenticated && isLoggedIn && <LoadingView />}
                      {!isAuthenticated && !isLoggedIn && <PublicViews />}
                    </>
                  </RedirectToHttps>
                </MuiThemeProvider>
              </ThemeProvider>
            </>
          );
        }}
      />
    </BrowserRouter>
  );
};

export default App;
