import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setInitialCurrentPoliza,
  postPedidoCotizacion,
  getDetalleCotizacion,
  clearCurrentPoliza,
  setCurrentPoliza,
  setContratarCurrentPoliza,
} from "../actions";
import PolizaSelector from "~components/bussines/polizaSelector/PolizaSelector";
import ContentSection from "~components/styled/contentSection/ContentSection";
import { useState } from "react";
import { TC, TP, TR } from "~constants/tipoPoliza";

const PENDIENTE = "pendiente";
const COMPLETA = "completa";
const MAX_REQUESTS = 4;

const useReduxSelector = () =>
  useSelector((state) => {
    const cotizadorReducer = state.segurosReducers.cotizadorReducer;

    return {
      polizas: cotizadorReducer.detalleCotizacion
        ? cotizadorReducer.detalleCotizacion.companias
        : [],
      polizasRequestCount: cotizadorReducer.polizasRequestCount,

      cotizacionId: cotizadorReducer.cotizacionId,

      detalleCotizacion: cotizadorReducer.detalleCotizacion,
      currentPoliza: cotizadorReducer.currentPoliza,
    };
  });

const ListadoCotizacionesStep = ({ currentState }) => {
  const dispatch = useDispatch();
  const {
    polizas,
    currentPoliza,
    polizasRequestCount,
    cotizacionId,
    detalleCotizacion,
  } = useReduxSelector();

  const [polizasCompleted, setPolizasCompleted] = useState([]);
  const countComplete = getComplete(polizasCompleted).length;

  useEffect(() => {
    dispatch(clearCurrentPoliza());
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (!currentPoliza.open) {
      dispatch(postPedidoCotizacion(currentState));
    }
    //eslint-disable-next-line
  }, [dispatch]);

  const isPending =
    polizasRequestCount <= MAX_REQUESTS &&
    getCountComplete(polizas) < polizas.length;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isPending && cotizacionId) {
        dispatch(getDetalleCotizacion(cotizacionId));
      }
    }, polizasRequestCount * 1000);
    return () => clearTimeout(timer);
  }, [dispatch, isPending, cotizacionId, polizasRequestCount]);

  useEffect(() => {
    let polizasConCotizaciones = polizas;

    //all completed and still without currentPoliza set
    if (!isPending && !currentPoliza.value) {
      polizasConCotizaciones = getPolizasConCotizaciones(polizas);

      if (polizasConCotizaciones.length > 0) {
        const defaultPoliza = getDefaultPoliza(polizasConCotizaciones);

        dispatch(
          setInitialCurrentPoliza({
            value: defaultPoliza,
            open: false,
            contratada: false,
          })
        );
      }
    }
  }, [isPending, dispatch]);

  useEffect(() => {
    const newCountComplete = getComplete(polizas).length;
    const hasChanged =
      countComplete !== newCountComplete ||
      polizasCompleted.length !== polizas.length;

    if (hasChanged && cotizacionId)
      setPolizasCompleted(
        getPolizasCompleted(polizasCompleted, polizas, dispatch)
      );
  }, [countComplete, polizasCompleted, polizas, dispatch]);

  const handleOpen = (poliza) => {
    dispatch(
      setCurrentPoliza({ value: poliza, open: true, contratada: false })
    );
  };

  const handleContratar = () => {
    dispatch(
      setContratarCurrentPoliza({
        value: currentPoliza.value,
        open: true,
        contratada: true,
      })
    );
  };

  return (
    <ContentSection>
      <PolizaSelector
        polizas={polizas}
        currentPoliza={currentPoliza.value}
        polizasCompleted={polizasCompleted}
        isPending={isPending || !detalleCotizacion}
        onOpen={handleOpen}
        onContratar={handleContratar}
      />
    </ContentSection>
  );
};

export default ListadoCotizacionesStep;

const getCountComplete = (list) => {
  return list.filter((poliza) => {
    return poliza.estado === "completa";
  }).length;
};
const getPolizasCompleted = (polizasCompleted, polizas, dispatch) => {
  //polizas already loaded in previous GET
  const loadedPolizas = getComplete(polizasCompleted);

  //polizas loaded in the last GET
  const receivedPolizas = polizasListDifference(polizas, polizasCompleted);

  sendToAnalytics(dispatch, polizas, loadedPolizas, receivedPolizas);

  //union between previous polizas and the new ones
  let result = loadedPolizas.concat(receivedPolizas);

  result = result.sort((x, y) => {
    const inOrder =
      (x.estado === COMPLETA && y.estado === PENDIENTE) ||
      (x.estado === COMPLETA &&
        y.estado === COMPLETA &&
        x.cotizaciones.length > 0 &&
        y.cotizaciones.length === 0);
    return inOrder ? -1 : 1;
  });

  return result;
};

//USEFUL AND AUXILIAR FUNCTIONS RELATED TO POLIZAS
const polizasListDifference = (firstList, secondList) => {
  return firstList.filter((poliza) => {
    const isPoliza = secondList.find((newPoliza) => {
      return poliza.companiaId === newPoliza.companiaId;
    });
    return !isPoliza;
  });
};

const getComplete = (list) => {
  return list.filter((poliza) => {
    return poliza.estado === COMPLETA;
  });
};

const getDefaultPoliza = (polizas) => {
  const polizaTC = polizas[0].cotizaciones.find((item) => {
    return item.tipoPlanComercial === TC ? item : null;
  });
  const polizaTP = polizas[0].cotizaciones.find((item) => {
    return item.tipoPlanComercial === TP ? item : null;
  });
  const polizaTR = polizas[0].cotizaciones.find((item) => {
    return item.tipoPlanComercial === TR ? item : null;
  });

  const defaultPoliza = polizaTP ? polizaTP : polizaTC ? polizaTC : polizaTR;

  return defaultPoliza;
};

const getPolizasConCotizaciones = (polizas) => {
  return polizas.filter((poliza) => {
    const polizaTC = poliza.cotizaciones.find((item) => {
      return item.tipoPlanComercial === TC ? item : null;
    });
    const polizaTP = poliza.cotizaciones.find((item) => {
      return item.tipoPlanComercial === TP ? item : null;
    });
    const polizaTR = poliza.cotizaciones.find((item) => {
      return item.tipoPlanComercial === TR ? item : null;
    });
    const hasPoliza = polizaTC || polizaTP || polizaTR;
    return poliza.estado === COMPLETA && hasPoliza;
  });
};

const sendToAnalytics = (dispatch, polizas, loadedPolizas, newPolizas) => {
  const alreadySent = getComplete(newPolizas);
  alreadySent.map((poliza, index) => {
    const ordenRecepcion = index + loadedPolizas.length + 1;
    const totalPolizas = polizas.length;
    //dispatch(sendPolizaReceivedToAnalytics(poliza, ordenRecepcion, totalPolizas));
    return 1;
  });
};
