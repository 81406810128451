import React from "react";
import { Switch, Redirect } from "react-router-dom";
import useAsyncReducer from "~components/app/useAsyncReducer";
import LoadingView from "~components/styled/loadingView/LoadingView";

//Routes
import InicioRoutes from "~components/app/agenciero/inicio/Routes";
import CreditosRoutes from "~components/app/agenciero/creditos/Routes";
import SegurosRoutes from "~components/app/agenciero/seguros/Routes";
import InfoRoutes from "~components/app/agenciero/info/Routes";
import AutosRoutes from "~components/app/agenciero/autos/Routes";

//Reducers
import CreditosReducers from "./creditos/Reducers";
import SharedReducers from "./shared/Reducers";

import TerminosYCondiciones from "~components/app/agenciero/shared/terminosYCondiciones/TerminosYCondiciones";

const AgencieroRoutes = () => {
  const loading = useAsyncReducer([CreditosReducers, SharedReducers]);
  return (
    <>
      {loading && <LoadingView />}
      {!loading && (
        <>
          <TerminosYCondiciones />
          <Switch>
            {InicioRoutes()}
            {CreditosRoutes()}
            {SegurosRoutes()}
            {InfoRoutes()}
            {AutosRoutes()}
            <Redirect to="/" />
          </Switch>
        </>
      )}
    </>
  );
};

export default AgencieroRoutes;
