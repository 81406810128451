import React, { Fragment } from "react";
import muiMGTheme from "~css/muiMGTheme";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { TC, TP, TR } from "~constants/tipoPoliza";
import Paper from "@material-ui/core/Paper";
import ContentLoader from "react-content-loader";
import PolizaButton from "../polizaButton/PolizaButton";

const useStyles = makeStyles((theme) => ({
  paperTitle: {
    background: muiMGTheme.palette.white,
    borderRadius: "solid 1px 1px 1px 1px",
  },
  headerTitle: {
    textAlign: "center",
    fontWeight: 800,
    color: muiMGTheme.palette.violeta,
    paddingTop: 5,
    paddingBottom: 5,
    lineHeight: 1.29,
    display: "block",
    flex: 1,
    whiteSpace: "pre-wrap",
    fontSize: "1rem",
  },
  disabled: {},
  button: {
    "&$disabled": {
      background: muiMGTheme.palette.white,
      color: muiMGTheme.palette.grey5,
      lineHeight: "16px",
      fontWeight: "bold",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      padding: 0,
      width: "100%",
      height: "100%",
    },
    justifyContent: "center",
    display: "flex",
    alignItems: "baseline",
    padding: 0,
    width: "100%",
    background: muiMGTheme.palette.white,
    color: muiMGTheme.palette.grey5,
    fontWeight: "bold",
    lineHeight: "16px",
    height: "100%",
    borderRadius: 0,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "strech",
    flexDirection: "row",
  },
}));

const CompaniaGroup = ({
  isLoading,
  currentPoliza,
  compania,
  onPolizaSelected,
}) => {
  const polizaTC = compania.cotizaciones.find((item) => {
    return item.tipoPlanComercial === TC ? item : null;
  });
  const polizaTP = compania.cotizaciones.find((item) => {
    return item.tipoPlanComercial === TP ? item : null;
  });
  const polizaTR = compania.cotizaciones.find((item) => {
    return item.tipoPlanComercial === TR ? item : null;
  });

  const handleClick = (poliza) => () => {
    if (onPolizaSelected) onPolizaSelected({ ...poliza, ...compania });
  };

  const hasPolizas = polizaTC || polizaTP || polizaTR;

  const classes = useStyles();
  return (
    <>
      {(isLoading || hasPolizas) && (
        <div style={{ paddingTop: 15 }}>
          <Paper className={classes.paperTitle}>
            <Typography variant={"h6"} className={classes.headerTitle}>
              {!isLoading && compania.compania}
              {isLoading && (
                <ContentLoader
                  height={10}
                  width={93}
                  speed={2}
                  style={{ width: 93 }}
                  primaryColor="#f7f8ff"
                  secondaryColor="#6464BB"
                  //backgroundColor="#f7f8ff"
                  //foregroundColor="#6464BB"
                >
                  <rect x="0" y="0" rx="8" ry="8" width="92" height="10" />
                </ContentLoader>
              )}
            </Typography>
            <Fragment>
              {isLoading && (
                <div className={classes.loadingContainer}>
                  <PolizaButton isLoading />
                  <PolizaButton isLoading />
                  <PolizaButton isLoading />
                </div>
              )}
              {!isLoading && hasPolizas && (
                <div className={classes.loadingContainer}>
                  <PolizaButton
                    onClick={handleClick}
                    poliza={polizaTC}
                    selected={
                      currentPoliza &&
                      polizaTC &&
                      currentPoliza.cotizacionPlanId ===
                        polizaTC.cotizacionPlanId
                    }
                  />
                  <PolizaButton
                    onClick={handleClick}
                    poliza={polizaTP}
                    selected={
                      currentPoliza &&
                      polizaTP &&
                      currentPoliza.cotizacionPlanId ===
                        polizaTP.cotizacionPlanId
                    }
                  />
                  <PolizaButton
                    onClick={handleClick}
                    poliza={polizaTR}
                    selected={
                      currentPoliza &&
                      polizaTR &&
                      currentPoliza.cotizacionPlanId ===
                        polizaTR.cotizacionPlanId
                    }
                  />
                </div>
              )}
            </Fragment>
          </Paper>
        </div>
      )}
    </>
  );
};

export default CompaniaGroup;
