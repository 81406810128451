import { logEventAnalytics } from "../libs/amplitudeHelper";

const analyticsMiddleware = store => next => action => {
  if (action.analytics) {
    try {
      console.debug("Analytics track", action.analytics);

      logEventAnalytics(action.analytics.eventName, {
        ...action.analytics.eventData,
        deviceInfo: { ...store.getState().nativeMessagesReducer.deviceInfo }
      });
    } catch (error) {
      console.error("Analytics error", error);
    }
  }

  const sendToAnalytics =
    action.type.includes("/") &&
    !(action.meta && action.meta.doNotSendToAnalytics);
  console.log("sendToAnalytics", action.type, sendToAnalytics);

  if (sendToAnalytics) {
    try {
      logEventAnalytics(
        action.type,
        action.payload
          ? {
              ...action.payload,
              deviceInfo: {
                ...store.getState().nativeMessagesReducer.deviceInfo
              }
            }
          : null
      );
    } catch (error) {
      console.error("Analytics error", error);
    }
  }

  return next(action);
};

export default analyticsMiddleware;
