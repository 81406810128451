import React from "react";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@material-ui/styles";

import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import CheckIcon from "~assets/icons/icon-check-big.svg";

const Success = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            label={"Volver al inicio"}
            onClick={() => {
              history.push("/");
            }}
          />
        </ContentSection>
      }
    >
      <img src={CheckIcon} className={classes.img} />
      <Typography className={classes.title}>{"¡Listo!"}</Typography>
      <Typography className={classes.text}>
        Ya recibimos tus datos y el comprobante de tu pago.
      </Typography>
      <Typography className={classes.text}>
        Dentro de las próximas <b>24 horas</b> vas a verlo reflejado en tu
        resumen.
      </Typography>
      <Typography className={classes.text}>¡Muchas gracias!</Typography>
    </ContentSectionWithActions>
  );
};

export default Success;

const useStyles = makeStyles(theme => ({
  img: { marginBottom: pxToRem(38), marginTop: "25%", height: 140 },
  title: {
    fontSize: pxToRem(24),
    fontWeight: "bold",
    lineHeight: 1.25,
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: pxToRem(8)
  },
  text: {
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "center",
    color: "#616161",
    marginLeft: pxToRem(25),
    marginRight: pxToRem(25)
  }
}));
