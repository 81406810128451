import React from "react";
import MaskedInput from "react-text-mask";

const CBUInput = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(rawValue = "") => {
        const value = rawValue.replace(/\s/g, "");

        let mask = [
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];

        return mask;
      }}
      placeholderChar={"\u2000"}
    />
  );
};

export default CBUInput;
