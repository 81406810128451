import React from "react";
import muiMGTheme from "~css/muiMGTheme";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import CompaniaGroup from "../companiaGroup/CompaniaGroup";
import icon_tc_incendio from "~assets/icons/icon_tc_incendio.svg";
import icon_tp from "~assets/icons/icon_tp.svg";
import icon_tr from "~assets/icons/icon_tr.svg";
import Button from "@material-ui/core/Button";
import EmptyState from "../emptyState/emptyState";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    minHeight: "calc( 100vh - 120px )",
  },
  headerContainer: {
    display: "flex",
    position: "sticky",
    top: 0,
    zIndex: 1,
    alignItems: "flex-end",
    width: "100%",
    justifyContent: "center",
    background: muiMGTheme.palette.white,
  },
  iconTextContainer: {
    flex: 1,
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerColumn: {
    flex: 1,
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerColumnMiddle: {
    flex: 1,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  divider: {
    width: 1,
    height: 90,
  },
  headerTitle: {
    textAlign: "center",
    fontWeight: 800,
    paddingTop: 5,
    color: muiMGTheme.palette.violeta,
    lineHeight: 1.1,
    display: "block",
    flex: 1,
    whiteSpace: "pre-wrap",
  },
  polizasContainer: {
    paddingBottom: 56,
  },
  contratar: {
    display: "flex",
    position: "absolute",
    bottom: "0",
    height: "56px",
    width: 250,
    color: muiMGTheme.palette.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    borderRadius: 50,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  buttonContratarContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "20px",
  },
}));

const PENDIENTE = "pendiente";
const COMPLETA = "completa";
const MAX_LOADING = 3;

const PolizaSelector = ({
  isPending,
  polizas,
  currentPoliza,
  polizasCompleted,
  onOpen,
  onContratar,
}) => {
  const countComplete = getComplete(polizasCompleted).length;

  const hasNotResults = !isPending && countComplete === 0;

  const classes = useStyles();
  return (
    <>
      {hasNotResults && <EmptyState />}
      {!hasNotResults && (
        <div className={classes.root}>
          <Paper className={classes.headerContainer} elevation={1} square>
            <div className={classes.headerColumn}>
              <img src={icon_tc_incendio} alt="" />
              <Typography className={classes.headerTitle}>
                {"Incendio Total\ny Robo"}
              </Typography>
            </div>
            <div className={classes.headerColumnMiddle}>
              <Divider className={classes.divider} />
              <div className={classes.iconTextContainer}>
                <img src={icon_tp} alt="" />
                <Typography className={classes.headerTitle}>
                  {"Terceros\nPremium"}
                </Typography>
              </div>
              <Divider className={classes.divider} />
            </div>
            <div className={classes.headerColumn}>
              <img src={icon_tr} alt="" />
              <Typography className={classes.headerTitle}>
                {"Todo\nRiesgo"}
              </Typography>
            </div>
          </Paper>
          <div className={classes.polizasContainer}>
            {countComplete < MAX_LOADING && (
              <PolizaList
                isPending={isPending}
                currentPoliza={currentPoliza}
                polizas={polizasCompleted}
                count={MAX_LOADING}
                onPolizaSelected={onOpen}
              />
            )}
            {countComplete >= MAX_LOADING &&
              countComplete < polizasCompleted.length && (
                <PolizaList
                  isPending={isPending}
                  currentPoliza={currentPoliza}
                  polizas={polizasCompleted}
                  count={countComplete + 1}
                  onPolizaSelected={onOpen}
                />
              )}
            {countComplete === polizas.length && (
              <PolizaList
                isPending={isPending}
                currentPoliza={currentPoliza}
                polizas={polizasCompleted}
                count={countComplete}
                onPolizaSelected={onOpen}
              />
            )}
          </div>
          <div className={classes.buttonContratarContainer}>
            <Button
              className={classes.contratar}
              onClick={onContratar}
              variant="contained"
              color="primary"
              disabled={!currentPoliza}
            >
              QUIERO ESTA PÓLIZA
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
export default PolizaSelector;

const PolizaList = ({
  isPending,
  currentPoliza,
  polizas,
  count,
  onPolizaSelected,
}) => {
  const result = polizas.length !== 0 ? polizas : loadingList;

  return result.slice(0, count).map((compania) => {
    return (
      <CompaniaGroup
        key={compania.companiaId}
        isLoading={compania.estado === PENDIENTE && isPending}
        compania={compania}
        currentPoliza={currentPoliza}
        onPolizaSelected={onPolizaSelected}
      />
    );
  });
};

//USEFUL AND AUXILIAR FUNCTIONS RELATED TO POLIZAS
const getComplete = (list) => {
  return list.filter((poliza) => {
    return poliza.estado === COMPLETA;
  });
};

const loadingList = [
  { companiaId: "loading1", estado: "pendiente", cotizaciones: [] },
  { companiaId: "loading2", estado: "pendiente", cotizaciones: [] },
  { companiaId: "loading3", estado: "pendiente", cotizaciones: [] },
];
