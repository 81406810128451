const isCreditCardNumberValid = (number) => {
  if (number.length !== 16) return false;

  let suma = 0;

  number.split("").forEach((stringDigit, index) => {
    let posicionImpar = (index + 1) % 2 !== 0;
    let digit = parseInt(stringDigit);
    suma += !posicionImpar ? digit : digit * 2 < 10 ? digit * 2 : digit * 2 - 9;
    return suma;
  });

  let esSumaMultiploDeDiez = suma % 10 == 0;

  return esSumaMultiploDeDiez;
};

const getCreditCardType = (accountNumber) => {
  //MasterCard
  if (/^5[1-5]/.test(accountNumber)) {
    return "mastercard";
  }

  //Visa
  if (/^4/.test(accountNumber)) {
    return "visa";
  }

  //AmEx
  if (/^3[47]/.test(accountNumber)) {
    return "amex";
  }

  return null;
};

export { isCreditCardNumberValid, getCreditCardType };
