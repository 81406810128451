import React from "react";
import NumberFormat from "react-number-format";

const CurrencyText = props => {
  return (
    <NumberFormat
      displayType={"text"}
      thousandSeparator
      isNumericString
      prefix="$"
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={0}
      {...props}
    />
  );
};

export default CurrencyText;
